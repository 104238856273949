
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply IBM Plex Mono font globally */
* {
  font-family: "IBM Plex Mono", monospace;
}

/* Add vintage effects */
@layer base {
  body {
    @apply bg-gray-50; /* This is a very light gray, almost white */
    position: relative;
  }

  /* Base noise texture */
  body::before {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    opacity: 0.3;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.85' numOctaves='4' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)'/%3E%3C/svg%3E");
    mix-blend-mode: multiply;
    min-height: 100vh;
  }

  /* Additional speckle layer */
  body::after {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    opacity: 0.15;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 150 150' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='speckles'%3E%3CfeTurbulence type='turbulence' baseFrequency='1.3' numOctaves='2' seed='5' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23speckles)'/%3E%3C/svg%3E");
    mix-blend-mode: multiply;
    min-height: 100vh;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');